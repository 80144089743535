<template>
    <div class="content">
        <div class="content-card">
            <div class="row">
                <div class="col lg-6 sm-0"></div>
                <div class="col lg-6 sm-12">
                    <div class="comission">
                        <transition name="fade" mode="out-in">
                            <div v-if="showForm" class="comission__block" key="form">
                                <div class="text_32_b tac comission__title">Быстрая оценка авто</div>
                                <div v-if="sended" class="text_24_b tac">
                                    Заявка успешно отправлена, менеджер свяжется с вами в ближайшее время
                                </div>
                                <form @submit.prevent="submit()" class="user-form user-form--column" v-else>
                                    <div class="user-form__content">
                                        <div class="form-group" v-for="(control, name) in form" :key="name">
                                            <div class="form-field form-field--wide">
                                                <div class="form-field__label">
                                                    {{ control.label }}
                                                    <span class="error" v-if="errorMessages[name]">{{
                                                        errorMessages[name][0]
                                                    }}</span>
                                                </div>
                                                <div class="form-field__component">
                                                    <input
                                                        class="form-group__input"
                                                        v-if="control.type === 'text'"
                                                        type="text"
                                                        required
                                                        v-model="form[name].value"
                                                    />
                                                    <input
                                                        class="form-group__input"
                                                        v-if="control.type === 'number'"
                                                        type="number"
                                                        required
                                                        v-model="form[name].value"
                                                    />
                                                    <vue-phone-number-input
                                                        v-if="control.type === 'tel'"
                                                        no-flags
                                                        :preferred-countries="['RU']"
                                                        :translations="phoneTranslations"
                                                        v-model="form[name].value"
                                                    />

                                                    <div
                                                        v-if="control.type === 'select'"
                                                        class="select"
                                                        :class="{ 'select--open': control.open }"
                                                    >
                                                        <div
                                                            class="select__current"
                                                            @click="control.open = !control.open"
                                                            v-html="
                                                                control.value ||
                                                                    control.options[Object.keys(control.options())[0]]
                                                            "
                                                        ></div>
                                                        <transition name="fade">
                                                            <div class="select__dropdown" v-if="control.open">
                                                                <div
                                                                    class="select__option text_16"
                                                                    @click="
                                                                        control.open = false;
                                                                        control.value = label;
                                                                    "
                                                                    :value="optionValue"
                                                                    v-for="(label,
                                                                    optionValue,
                                                                    index) in control.options()"
                                                                    :key="index"
                                                                    :selected="index == 0"
                                                                    v-html="label"
                                                                ></div>
                                                            </div>
                                                        </transition>
                                                    </div>

                                                    <div class="form-field__radio-list" v-if="control.type === 'radio'">
                                                        <label
                                                            class="form-field__radio"
                                                            v-for="(item, radio_name) in control.list"
                                                            :key="radio_name"
                                                        >
                                                            <input
                                                                type="radio"
                                                                :name="name"
                                                                :value="item"
                                                                v-model="form[name].value"
                                                            />
                                                            <span>{{ item }}</span>
                                                        </label>
                                                    </div>

                                                    <template v-if="name === 'agree'">
                                                        <label class="flex-c form-field__checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="agree"
                                                                v-model="form[name].value"
                                                            />
                                                            <span>
                                                                Я ознакомилась(ся) с политикой конфиденциальности и даю
                                                                свое согласие на
                                                                <a
                                                                    href="https://arlan-auto.ru/informaciya?tab=4&subTab=4"
                                                                    target="_blank"
                                                                    >обработку персональных данных</a
                                                                >
                                                            </span>
                                                        </label>
                                                        <div class="error" v-if="errorMessages.agree">
                                                            {{ errorMessages.agree[0] }}
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <button class="button" type="submit" :disabled="!form.agree">
                                                Получить результаты
                                                <div class="loader" v-if="requestStatus === 'loading'"></div>
                                            </button>
                                        </div>
                                        <div class="text_20 tac submit-error" v-if="requestStatus === 'error'">
                                            Произошла ошибка отправки формы, <br />позвоните по номеру телефона
                                            <a href="tel:88482613813">8 (8482) 613 - 813</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-else class="comission__block" key="text">
                                <div class="text_32_b tac comission__title">
                                    <span class="color-red">Фиксированная </span> комиссия при продаже авто
                                </div>
                                <div class="text_24_b">
                                    До 1 000 000 - <span class="color-red">10 000</span> руб<br />
                                    Более 1000 000 - <span class="color-red">20 000</span> руб<br />
                                    до 31.10.2021 г.
                                </div>
                                <p class="text_16 comission__dash">Продайте свой автомобиль быстро и выгодно</p>
                                <div class="tac">
                                    <div
                                        class="button"
                                        @click="
                                            () => {
                                                showForm = true;
                                            }
                                        "
                                    >
                                        Оставить заявку
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <div class="bonuses" v-if="!sended">
                            <div class="bonuses__title text_16 bold tac">ОСТАВЬТЕ ЗАЯВКУ И ПОЛУЧИТЕ БОНУСЫ</div>
                            <div class="flex">
                                <div class="bonus flex-c">
                                    <div class="bonus__img">
                                        <img src="../../assets/img/comcar1.jpg" alt="" />
                                    </div>
                                    <div class="bonus__text">АВАНС ДО 80% В ТЕЧЕНИИ ЧАСА</div>
                                </div>
                                <div class="bonus flex-c">
                                    <div class="bonus__img">
                                        <img src="../../assets/img/comcar2.jpg" alt="" />
                                    </div>
                                    <div class="bonus__text">ПОДГОТОВКА АВТО БЕСПЛАТНО</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "Comission",
    data() {
        return {
            sended: false,
            requestStatus: "",
            showForm: false,
            errorMessages: {},
            form: {
                car: {
                    label: "Какая у вас марка и модель автомобиля?",
                    type: "text",
                    value: "",
                },
                year: {
                    label: "Укажите год выпуска автомобиля",
                    type: "select",
                    open: false,
                    value: 2000,
                    // года с 2000 по текущий
                    options() {
                        const res = {};
                        new Array(new Date().getFullYear() - 1999)
                            .fill()
                            .map((e, i) => {
                                return i + 2000;
                            })
                            .forEach((el) => (res[el] = el));
                        return res;
                    },
                },
                body: {
                    label: "Какой тип кузова?",
                    type: "radio",
                    list: {
                        sedan: "Седан",
                        universal: "Универсал",
                        offroad: "Внедорожник",
                        pickup: "Пикап",
                        hatchback: "Хетчбек",
                        compartment: "Купе",
                        minivan: "Минивен",
                        van: "Фургон",
                    },
                },
                mileage: {
                    label: "Укажите пробег автомобиля",
                    type: "number",
                    value: "",
                },
                crash: {
                    label: "Попадала ли машина когда-либо в аварии?",
                    type: "radio",
                    list: {
                        dtpyesfail: "Да, требует ремонт",
                        dtpyessmail: "Да, всё починили",
                        dtpno: "Нет",
                    },
                },
                car_diagnostic: {
                    label: "Хотите пройти предпродажную диагностику в нашем сервисе? Это бесплатно",
                    type: "radio",
                    list: { diagnosticsYes: "Да", diagnosticNo: "Нет, уже есть" },
                },
                name: {
                    label: "Ваше имя",
                    type: "text",
                    value: "",
                },
                phone: {
                    label: "Ваш телефон",
                    type: "tel",
                    value: "",
                },
                agree: {
                    type: "checkbox",
                    value: 0,
                },
            },
            phoneTranslations: {
                countrySelectorLabel: "Код",
                countrySelectorError: "Ошибка",
                phoneNumberLabel: "Номер телефона",
                example: "Например:",
            },
        };
    },
    methods: {
        async submit() {
            this.requestStatus = "loading";
            axios.defaults.withCredentials = true;
            let postData = {};
            for (const key in this.form) {
                if (Object.hasOwnProperty.call(this.form, key)) {
                    const element = this.form[key];
                    postData[key] = element.type === "tel" ? "+7" + element.value : element.value;
                }
            }
            axios
                .get("https://api.arlan-auto.ru/api/v1/csrf_token")
                .then(() => {
                    axios
                        .post("https://api.arlan-auto.ru/api/v1/amo/create/lead/salon/car-commission", postData)
                        .then(() => {
                            this.sended = true;
                            this.requestStatus = "";
                        })
                        .catch((e) => {
                            console.log(`e =>`, e);
                            this.requestStatus = "error";
                            if (e.response.status === 422) {
                                this.errorMessages = Object.assign(e.response.data.data);
                            }
                        });
                })
                .catch((e) => {
                    console.log(`e =>`, e);
                    this.requestStatus = "error";
                });
        },
    },
};
</script>
<style lang="less">
@import url("../../assets/less/vars.less");
.content-card {
    background: linear-gradient(90deg, transparent 50%, #fff 50%),
        url(../../assets/img/com.jpg) center right / auto 100% no-repeat;
    background-attachment: fixed;
    min-height: 60vh;
    @media @sm {
        background: #fff;
        padding: 40px 30px;
    }
    .user-form {
        @media @sm {
            padding: 0;
        }
    }
}
.error {
    padding: 3px 5px;
    background: @red;
    color: #fff;
    margin: 5px 0;
    display: block;
    text-align: center;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 10px;
        padding: 4px;
        transform: translateY(-50%) rotate(45deg);
        background: @red;
    }
}
.bonus {
    padding: 5px;
    width: 245px;
    height: 85px;
    position: relative;
    justify-content: flex-end;
    flex-grow: 1;
    @media @sm {
        width: 100%;
        justify-content: center;
    }
    &::before {
        border-radius: 5px;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7));
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        right: -4px;
        width: 24px;
        height: 24px;
        margin-bottom: -12px;
        z-index: 5;
        border-radius: 50%;
        background: url(../../assets/img/lock.svg) center / 55% no-repeat, #f8d373;
    }
    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
            object-fit: cover;
            border-radius: 5px;
            width: 100%;
            height: 100%;
        }
    }
    &__text {
        position: relative;
        z-index: 2;
        color: #fff;
        font-weight: bold;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.05em;
    }
}
.comission {
    padding-left: 30px;
    @media @sm {
        padding-left: 0;
    }
    &__title {
        margin-bottom: 40px;
    }
    &__dash {
        padding-top: 20px;
        margin-bottom: 40px;
        position: relative;
        &::before {
            content: "";
            height: 2px;
            width: 80px;
            background: #000;
            top: 2px;
            left: 0;
            position: absolute;
        }
    }
}
.bonuses {
    margin-top: 40px;
    &__title {
        margin-bottom: 20px;
    }
    .flex {
        gap: 8px;
        @media @sm {
            flex-direction: column;
        }
    }
}
</style>
